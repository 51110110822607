import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  TableRow,
  TableHead,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  InputBase,
  Button,
  Grid,
  Box,
  Avatar,
} from "@mui/material";
import Loader from "../Loader/Loader";
import EditRoleDialog from "./EditRoleDialog";
import useLocales from "../../hooks/useLocales";
import { EditOutlined } from "@mui/icons-material";
import DeleteRoleDialog from "./DeleteRoleDialog";
import CreateRoleDialog from "./CreateRoleDialog";
import SearchIcon from "@mui/icons-material/Search";
import { roleHeading } from "./muiRoleComponentStyles";
import { useDispatch, useSelector } from "../../redux/store";
import { getRolesList } from "../../redux/slices/RoleSlice";
import CustomPagination from "../../hooks/useTablePagination";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  miniScrollBarStyles,
  styledTableCell,
} from "../../utils/muiComponentStyles";

const StyledTableRow = styled(TableRow)((props) => ({
  "&:nth-of-type(odd)": {
    background: props.theme.palette.background.bgTableOddRow,
  },
  "&:nth-of-type(even)": {
    background: props.theme.palette.background.bgTableEvenRow,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.MuiTableRow-root:hover": {
    background: props.theme.palette.background.bgTableRowHover,
  },
}));

const Index = () => {
  const { translate } = useLocales();
  const [page, setPage] = useState(() => {
    // Retrieve the page from localStorage or default to 0
    const savedPage = localStorage.getItem("pageRole");
    return savedPage ? Number(savedPage) : 0;
  });
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    // Retrieve the rowsPerPage from localStorage or default to 10
    const savedRowsPerPage = localStorage.getItem("rowsPerPageRole");
    return savedRowsPerPage ? Number(savedRowsPerPage) : 10;
  });
  const [roleDetail, setRoleDetail] = useState();
  const [filteredList, setFilteredList] = useState([]); // Initial filtered list
  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State to hold search term
  const dispatch = useDispatch();

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditDialog(false);
    setDeleteDialog(false);
  };

  useEffect(() => {
    dispatch(getRolesList(handleApiRes));
  }, []);
  useEffect(() => {
    // Check if any filters are applied
    const areFiltersApplied = searchTerm;

    if (!areFiltersApplied) {
      // If no filters are applied, retrieve saved page from localStorage
      const savedPage = localStorage.getItem("pageRole");
      if (savedPage) {
        setPage(Number(savedPage)); // Set the page to the saved value
      }
    } else {
      // If filters are applied, reset page to 0
      setPage(0);
    }
  }, [searchTerm]);
  let { rolesList } = useSelector((state) => state.roleReducer);

  const handleApiRes = (response) => {
    if (response.status) {
      setLoading(false);
      setFilteredList(rolesList); // Set filteredList to rolesList initially
    }
  };

  // Load search term from localStorage when component mounts
  useEffect(() => {
    const savedSearchTerm = localStorage.getItem("roleSearch");
    if (savedSearchTerm) {
      setSearchTerm(savedSearchTerm);
      handleSearch(savedSearchTerm); // Set filtered list based on saved search term
    } else {
      setFilteredList(rolesList); // Default to showing all roles if no search term
    }
  }, [rolesList]); // Added rolesList to dependency array

  const handleSearch = (value) => {
    // Set search term state and save to localStorage
    setSearchTerm(value);
    localStorage.setItem("roleSearch", value);

    setPage(0);
    let filterArray = [];
    const toBeSearched = value.toString().toLowerCase();

    // Check if the search term is empty
    if (toBeSearched === "") {
      // If empty, set filteredList to the entire rolesList
      filterArray = rolesList;
    } else {
      // Filter rolesList based on the search term
      rolesList.forEach((item) => {
        const name = item.name.toString().toLowerCase();
        if (name.includes(toBeSearched)) {
          filterArray.push(item);
        }
      });
    }

    setFilteredList(filterArray);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    localStorage.setItem("pageRole", newPage - 1); // Persist the page in localStorage
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    localStorage.setItem("rowsPerPageRole", newRowsPerPage); // Persist the rowsPerPage in localStorage
    setPage(0); // Reset to the first page when changing rows per page
    localStorage.setItem("pageRole", 0); // Persist the reset page in localStorage
  };

  return (
    <>
      {openDialog && (
        <CreateRoleDialog
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      {deleteDialog && (
        <DeleteRoleDialog
          roleDetail={roleDetail}
          deleteDialog={deleteDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      <Grid spacing={2} container>
        <Grid item xs={12} md={8} sm={8} lg={10}>
          <InputBase
            value={searchTerm} // Set the input value to searchTerm
            onChange={(e) => handleSearch(e.target.value)}
            sx={{
              width: "100%",
              height: "38px",
              border: "1px solid rgba(169, 169, 169, 0.6)",
              borderRadius: "10px",
              backgroundColor: "background.bgSearchBar",
            }}
            placeholder={translate("searchRole")}
            inputProps={{ "aria-label": "search" }}
            startAdornment={
              <SearchIcon
                sx={{
                  color: "rgba(151, 151, 151, 0.68);",
                  paddingLeft: "3px",
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={4} sm={4} lg={2}>
          <Button
            onClick={() => handleOpenDialog()}
            disableRipple
            sx={{
              backgroundColor: "background.bgLayoutB",
              borderRadius: "10px",
              width: "100%",
              "&:hover": { backgroundColor: "background.bgLayoutB" },
            }}
          >
            <Typography sx={roleHeading} variant="roleHeadingBoldButton">
              {translate("createRole")}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          marginTop: "30px",
          borderRadius: "10px",
          [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
          ...miniScrollBarStyles,
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ backgroundColor: "background.bgLayoutA" }}>
            <TableRow>
              <TableCell
                width={"40%"}
                sx={{ backgroundColor: "background.bgLayoutA" }}
              >
                <Typography sx={roleHeading} variant="roleTableHeadingBold">
                  {translate("name")}
                </Typography>
              </TableCell>

              <TableCell
                width={"30%"}
                sx={{ backgroundColor: "background.bgLayoutA" }}
                align="left"
              >
                <Typography sx={roleHeading} variant="roleTableHeadingBold">
                  {translate("description")}
                </Typography>
              </TableCell>
              <TableCell
                width={"30%"}
                sx={{ backgroundColor: "background.bgLayoutA" }}
                align="right"
              >
                <Typography
                  sx={roleHeading}
                  variant="roleTableHeadingBold"
                ></Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : rolesList.length < 1 ? (
              <TableRow>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {translate("noRoleAvailable")}
                </Box>
              </TableRow>
            ) : (
              filteredList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow
                    hover
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{ ...styledTableCell, minWidth: "150px" }}
                      component="th"
                      scope="row"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: "background.bgLayoutB",
                            color: "white",
                            marginRight: "10px",
                          }}
                        >
                          {row?.name[0].toUpperCase()}
                        </Avatar>
                        <Typography
                          sx={roleHeading}
                          variant="roleTableCellName"
                        >
                          {row?.name}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell
                      sx={{ ...styledTableCell, minWidth: "200px" }}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      <Typography sx={roleHeading} variant="roleTableCellName">
                        {row?.description ? row?.description : "No Description"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={styledTableCell}
                      component="th"
                      scope="row"
                      align="right"
                    >
                      <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <IconButton
                          sx={{
                            backgroundColor: "background.bgLayoutA",
                            "&:hover": {
                              backgroundColor: "background.bgLayoutA",
                            },
                          }}
                          onClick={() => {
                            setEditDialog(true);
                            setRoleDetail(row);
                          }}
                        >
                          <EditOutlined sx={{ color: "white" }} />
                        </IconButton>
                        <IconButton
                          sx={{
                            marginLeft: "10px",
                            backgroundColor: "background.bgLayoutB",
                            "&:hover": {
                              backgroundColor: "background.bgLayoutB",
                            },
                          }}
                          onClick={() => {
                            setRoleDetail(row);
                            setDeleteDialog(true);
                          }}
                        >
                          <DeleteOutlineIcon sx={{ color: "white" }} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        // page={page}
        // setPage={setPage}
        // rowsPerPage={rowsPerPage}
        // handleChangePage={handleChangePage}
        // filteredList={filteredList}
        // setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={handleChangePage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        filteredList={filteredList}
        setRowsPerPage={handleRowsPerPageChange}
      />
      {editDialog && (
        <EditRoleDialog
          roleDetail={roleDetail}
          editDialog={editDialog}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </>
  );
};

export default Index;
