import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  Button,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  InputBase,
  Typography,
  TableContainer,
  Autocomplete,
  TextField,
} from "@mui/material";
import Loader from "../Loader/Loader";
import { Search } from "@mui/icons-material";
import CustomPagination from "../../hooks/useTablePagination";
import {
  StyledTableRow,
  autoCompleteStyle,
  miniScrollBarStyles,
  styledTableCell,
  textfield,
  userHeading,
} from "../../utils/muiComponentStyles";

import { useNavigate } from "react-router-dom";
import ToasterContext from "../../utils/context/toasterContext";
import { dispatch, useSelector } from "../../redux/store";
import { getAllMuncipalities } from "../../redux/slices/muncipalitySlice";

const Index = ({ tableHeader, bodyData, loading }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(() => {
    // Retrieve the page from localStorage or default to 0
    const savedPage = localStorage.getItem("pageDashboard");
    return savedPage ? Number(savedPage) : 0;
  });
  const tableRef = useRef(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dateTimeObj, setDateTimeObj] = useState({});
  const [filteredList, setFilteredList] = useState([]);
  const [toBeSearched, setToBeSearched] = useState("");
  const fireToasterContext = useContext(ToasterContext);
  const [dateError, setDateError] = useState({
    startDate: false,
    endDate: false,
  });

  const [appStatus, setAppStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const statuses = [
    "All",
    "New Application",
    "Pending Approval",
    "Approved",
    "Waiting for Payment",
    "Void/Rejected",
  ];

  let { muncipalityList } = useSelector((state) => state.muncipalityReducer);
  useEffect(() => {
    const fetchDataAndApplyFilters = async () => {
      try {
        // Dispatch the action to fetch municipalities
        await dispatch(getAllMuncipalities());

        // Get filters from localStorage
        const savedSearchFilter = localStorage.getItem("searchFilter");
        const savedAppStatusFilter = localStorage.getItem(
          "applicationStatusFilter"
        );
        const savedPaymentStatusFilter = localStorage.getItem(
          "paymentStatusFilter"
        );
        const savedStartDate = localStorage.getItem("startDate");
        const savedEndDate = localStorage.getItem("endDate");
        // const savedRowsPerPage = localStorage.getItem("rowsPerPageDashboard");
        // const savedPage = localStorage.getItem("pageDashboard");

        // Apply saved filters if available
        if (savedSearchFilter) setToBeSearched(savedSearchFilter);
        if (savedAppStatusFilter)
          handleAutoCompleteOnChange(null, savedAppStatusFilter);
        if (savedPaymentStatusFilter)
          handlePaymentStatusChange(null, savedPaymentStatusFilter);

        // If startDate or endDate exists in localStorage, set it in state
        if (savedStartDate || savedEndDate) {
          setDateTimeObj({
            startDate: savedStartDate || "",
            endDate: savedEndDate || "",
          });
          handleDateSearch(); // Apply date filter if saved
          setPage(0);
        }
        // if (savedRowsPerPage) setRowsPerPage(Number(savedRowsPerPage));
        // if (savedPage) setPage(Number(savedPage));
      } catch (error) {
        console.error("Error fetching municipalities data", error);
      }
    };

    // Fetch data and apply filters when the component mounts
    fetchDataAndApplyFilters();
  }, [bodyData, dispatch]);
  useEffect(() => {
    // Check if any filters are applied
    const areFiltersApplied =
      toBeSearched || appStatus || paymentStatus || dateTimeObj;

    if (!areFiltersApplied) {
      // If no filters are applied, retrieve saved page from localStorage
      const savedPage = localStorage.getItem("pageDashboard");
      if (savedPage) {
        setPage(Number(savedPage)); // Set the page to the saved value
      }
    } else {
      // If filters are applied, reset page to 0
      setPage(0);
    }
  }, [toBeSearched, appStatus, paymentStatus, dateTimeObj]);
  // Function to apply all filters
  const applyFilters = () => {
    let filteredData = bodyData;

    // Apply search filter
    if (toBeSearched) {
      const lowerCaseSearchTerm = toBeSearched.toLowerCase();
      filteredData = filteredData.filter((application) => {
        const municipalityName = muncipalityList
          .find((item) => item.id === application.muncipalityId)
          ?.name?.toLowerCase();
        return (
          municipalityName.includes(lowerCaseSearchTerm) ||
          application.permitNumber
            .toLowerCase()
            .includes(lowerCaseSearchTerm) ||
          application.projectAddress
            .toLowerCase()
            .includes(lowerCaseSearchTerm) ||
          application.ownerName.toLowerCase().includes(lowerCaseSearchTerm) ||
          application.jobDescription.toLowerCase().includes(lowerCaseSearchTerm)
        );
      });
    }

    // Apply application status filter
    if (appStatus && appStatus !== "All") {
      filteredData = filteredData.filter((el) => el.status === appStatus);
    }

    // Apply payment status filter
    if (paymentStatus) {
      filteredData = filteredData.filter((row) => {
        if (paymentStatus === "Paid") return row.paymentDone === true;
        if (paymentStatus === "Unpaid")
          return row.paymentDone === false || row.paymentDone === null;
        return true; // If "All" or null
      });
    }

    // Apply date range filter
    if (dateTimeObj.startDate && dateTimeObj.endDate) {
      filteredData = filteredData.filter((u) => {
        const createdAt = u.createdAt.substring(0, 10);
        return (
          createdAt >= dateTimeObj.startDate && createdAt <= dateTimeObj.endDate
        );
      });
    }

    // Update filtered list state
    setFilteredList(filteredData);
  };

  // Fetch data and apply filters on mount
  // useEffect(() => {
  //   const fetchDataAndApplyFilters = async () => {
  //     try {
  //       await dispatch(getAllMuncipalities());

  //       // Initialize from localStorage
  //       const savedRowsPerPage = localStorage.getItem("rowsPerPageDashboard");
  //       const savedPage = localStorage.getItem("pageDashboard");

  //       if (savedRowsPerPage) setRowsPerPage(Number(savedRowsPerPage));
  //       if (savedPage) setPage(Number(savedPage));

  //       // Other localStorage fetching logic...
  //     } catch (error) {
  //       console.error("Error fetching municipalities data", error);
  //     }
  //   };

  //   fetchDataAndApplyFilters();
  // }, [bodyData, dispatch]);

  // Update filtered list when bodyData changes
  useEffect(() => {
    if (filteredList && filteredList.length > 0) {
      setFilteredList(filteredList);
    } else {
      setFilteredList(bodyData); // Ensure filteredList is initialized with bodyData
    }
  }, [bodyData]);

  // Scroll to top when page changes
  useEffect(() => {
    if (page !== 0) {
      tableRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [page]);

  const handleSearch = (value) => {
    setToBeSearched(value);
    localStorage.setItem("searchFilter", value); // Save search filter
  };

  const handleAutoCompleteOnChange = (event, newValue) => {
    setAppStatus(newValue || "All");
    localStorage.setItem("applicationStatusFilter", newValue || "All");
  };

  const handlePaymentStatusChange = (event, newValue) => {
    setPaymentStatus(newValue || "");
    localStorage.setItem("paymentStatusFilter", newValue || "");
  };

  // const handleDateChange = (event) => {
  //   const { name, value } = event.target;
  //   setDateTimeObj((prev) => ({ ...prev, [name]: value }));
  //   localStorage.setItem(name, value);
  // };
  useEffect(() => {
    // Apply filters whenever the relevant state changes
    applyFilters();

    // Check if any filters are applied
    if (
      toBeSearched ||
      appStatus !== "All" ||
      paymentStatus ||
      (dateTimeObj.startDate && dateTimeObj.endDate)
    ) {
      // If filters are applied, reset page to 0
      setPage(0);
    } else {
      // If no filters are applied, retrieve saved page from localStorage
      const savedPage = localStorage.getItem("pageDashboard");
      if (savedPage) {
        setPage(Number(savedPage)); // Set the page to the saved value
      }
    }
  }, [toBeSearched, appStatus, paymentStatus, dateTimeObj, bodyData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    localStorage.setItem("pageDashboard", newPage - 1); // Persist page in localStorage
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    localStorage.setItem("rowsPerPageDashboard", newRowsPerPage); // Persist rowsPerPage in localStorage
    setPage(0); // Reset page to 0 when changing rows per page
    localStorage.setItem("pageDashboard", 0); // Persist the page reset to localStorage
  };

  const handleFilter = (bodyData, searchTerm) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return bodyData?.filter((application) => {
      const {
        muncipalityId,
        permitNumber,
        projectAddress,
        jobDescription,
        ownerName,
      } = application;
      const municipalityName =
        muncipalityList.find((item) => item.id === muncipalityId)?.name || "";
      return (
        municipalityName?.toLowerCase().includes(lowerCaseSearchTerm) ||
        permitNumber?.toLowerCase().includes(lowerCaseSearchTerm) ||
        projectAddress?.toLowerCase().includes(lowerCaseSearchTerm) ||
        ownerName?.toLowerCase().includes(lowerCaseSearchTerm) ||
        jobDescription?.toLowerCase().includes(lowerCaseSearchTerm)
      );
    });
  };

  const filteredData = handleFilter(filteredList, toBeSearched);

  const handleDateChange = (event) => {
    setPage(0);

    // Update the dateTimeObj state with the new date value
    const tempdateTimeObj = { ...dateTimeObj };
    tempdateTimeObj[event.target.name] = event.target.value;
    setDateTimeObj(tempdateTimeObj);

    // Persist the selected date in localStorage
    if (event.target.name === "startDate") {
      localStorage.setItem("startDate", event.target.value);
    } else if (event.target.name === "endDate") {
      localStorage.setItem("endDate", event.target.value);
    }
  };

  // const handleDateSearch = () => {
  //   // Retrieve dates from localStorage if not available in state
  //   const savedStartDate = localStorage.getItem("startDate");
  //   const savedEndDate = localStorage.getItem("endDate");

  //   const startDate = dateTimeObj.startDate || savedStartDate;
  //   const endDate = dateTimeObj.endDate || savedEndDate;

  //   // If neither start date nor end date is selected, reset the data and clear any errors
  //   if (!startDate && !endDate) {
  //     setDateError({
  //       startDate: false,
  //       endDate: false,
  //     });
  //     setFilteredList(bodyData); // Reset data to show all records
  //     return;
  //   }

  //   // If only one date is selected, show an error and do not filter
  //   if (!startDate || !endDate) {
  //     setDateError({
  //       startDate: !startDate,
  //       endDate: !endDate,
  //     });
  //     fireToasterContext.fireToasterHandler(
  //       false,
  //       "Please select both start and end dates"
  //     );
  //     return;
  //   }

  //   // If both dates are selected, proceed with filtering
  //   const filteredApplicants = bodyData.filter((u) => {
  //     // Filter by date (ignoring time)
  //     const startDateMatch = u.createdAt.substring(0, 10) >= startDate;
  //     const endDateMatch = u.createdAt.substring(0, 10) <= endDate;
  //     return startDateMatch && endDateMatch;
  //   });

  //   // Update the filtered data state
  //   setFilteredList(filteredApplicants);

  //   // Clear date errors after successful search
  //   setDateError({
  //     startDate: false,
  //     endDate: false,
  //   });
  // };
  const handleDateSearch = () => {
    // Retrieve dates from localStorage if not available in state
    const savedStartDate = localStorage.getItem("startDate");
    const savedEndDate = localStorage.getItem("endDate");

    const startDate = dateTimeObj.startDate || savedStartDate;
    const endDate = dateTimeObj.endDate || savedEndDate;

    // If both dates are empty, apply other filters (search, app status, payment status)
    if (!startDate && !endDate) {
      setDateError({
        startDate: false,
        endDate: false,
      });

      // Apply other filters like search, application status, and payment status
      let filteredData = bodyData;

      if (appStatus && appStatus !== "All") {
        filteredData = filteredData.filter((el) => el.status === appStatus);
      }

      if (paymentStatus) {
        filteredData = filteredData.filter((row) => {
          if (paymentStatus === "Paid") return row.paymentDone === true;
          if (paymentStatus === "Unpaid")
            return row.paymentDone === false || row.paymentDone === null;
          return true;
        });
      }

      filteredData = handleFilter(filteredData, toBeSearched);

      setFilteredList(filteredData); // Update the filtered list with other filters
      return;
    }

    // If only one date is selected, show an error and do not filter
    if (!startDate || !endDate) {
      setDateError({
        startDate: !startDate,
        endDate: !endDate,
      });
      fireToasterContext.fireToasterHandler(
        false,
        "Please select both start and end dates"
      );
      return;
    }

    // If both dates are selected, proceed with filtering by date
    const filteredApplicants = bodyData.filter((u) => {
      const startDateMatch = u.createdAt.substring(0, 10) >= startDate;
      const endDateMatch = u.createdAt.substring(0, 10) <= endDate;
      return startDateMatch && endDateMatch;
    });

    // Apply other filters like search, application status, and payment status
    let finalFilteredData = filteredApplicants;

    if (appStatus && appStatus !== "All") {
      finalFilteredData = finalFilteredData.filter(
        (el) => el.status === appStatus
      );
    }

    if (paymentStatus) {
      finalFilteredData = finalFilteredData.filter((row) => {
        if (paymentStatus === "Paid") return row.paymentDone === true;
        if (paymentStatus === "Unpaid")
          return row.paymentDone === false || row.paymentDone === null;
        return true;
      });
    }

    finalFilteredData = handleFilter(finalFilteredData, toBeSearched);

    // Update the filtered list
    setFilteredList(finalFilteredData);

    // Clear date errors after successful search
    setDateError({
      startDate: false,
      endDate: false,
    });
  };

  const dateTimeApiformat = (currentDate) => {
    const [datePart, timePart] = currentDate.split("T");
    const [yyyy, mm, dd] = datePart.split("-");
    const [hh, min] = timePart.split(":");
    const isAM = parseInt(hh, 10) < 12;
    const formattedDate = `${mm}/${dd}/${yyyy}`;
    const formattedTime = `${hh % 12 || 12}:${min} ${isAM ? "AM" : "PM"}`;

    return `${formattedDate} || ${formattedTime}`;
  };

  const handleApplicantRowClick = (data) => {
    localStorage.setItem("selectedApplication", JSON.stringify(data));
    navigate("/applications/application-detail");
  };

  const getMunicipalityName = (id) => {
    for (let i = 0; i < muncipalityList.length; i++) {
      if (muncipalityList[i].id == id) {
        return muncipalityList[i].name;
      }
    }
  };
  return (
    <Box>
      <div ref={tableRef} />
      <Grid spacing={2} container sx={{ my: 1 }}>
        <Grid item xs={6} md={2.5}>
          <InputBase
            value={toBeSearched}
            disabled={loading}
            onChange={(e) => handleSearch(e.target.value)}
            sx={{
              width: "100%",
              height: "38px",
              border: "1px solid rgba(169, 169, 169, 0.6)",
              borderRadius: 2,
              backgroundColor: "background.bgSearchBar",
              fontFamily: "Poppins",
            }}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            startAdornment={
              <Search
                sx={{
                  color: "rgba(151, 151, 151, 0.68);",
                  paddingLeft: "3px",
                }}
              />
            }
          />
        </Grid>

        <Grid item xs={6} md={2}>
          <Autocomplete
            disabled={loading}
            disablePortal
            id="application-status-autocomplete"
            options={statuses}
            value={appStatus} // Set the value to the state variable
            onChange={handleAutoCompleteOnChange}
            sx={{ fontFamily: "Poppins" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Application Status"
                size="small"
                sx={autoCompleteStyle}
                InputLabelProps={{
                  style: { fontFamily: "Poppins" },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <Autocomplete
            disabled={loading}
            disablePortal
            id="payment-status-autocomplete"
            options={["Paid", "Unpaid"]}
            value={paymentStatus} // Set the value to the state variable
            onChange={handlePaymentStatusChange}
            sx={{ fontFamily: "Poppins" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Payment Status"
                size="small"
                sx={autoCompleteStyle}
                InputLabelProps={{
                  style: { fontFamily: "Poppins" },
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={6} md={2}>
          <TextField
            disabled={loading}
            size="small"
            id="startDate"
            label="Start Date"
            name="startDate"
            type="date"
            value={dateTimeObj.startDate || ""} // Persist the value in the input field
            onChange={handleDateChange}
            inputProps={{
              max: new Date().toISOString().split("T")[0],
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ ...textfield, backgroundColor: "background.bgSearchBar" }}
            error={dateError.startDate}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            disabled={loading}
            size="small"
            id="endDate"
            label="End Date"
            name="endDate"
            type="date"
            value={dateTimeObj.endDate || ""} // Persist the value in the input field
            onChange={handleDateChange}
            inputProps={{
              max: new Date().toISOString().split("T")[0],
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ ...textfield, backgroundColor: "background.bgSearchBar" }}
            error={dateError.endDate}
          />
        </Grid>

        <Grid item xs={6} md={1.5}>
          <Button
            onClick={handleDateSearch}
            disableRipple
            disabled={loading}
            sx={{
              backgroundColor: "background.bgLayoutB",
              width: "100%",
              borderRadius: "10px",
              "&:hover": { backgroundColor: "background.bgLayoutB" },
              "&:disabled": {
                backgroundColor: "rgba(224, 224, 224, 1)",
              },
            }}
          >
            <Typography sx={userHeading} variant="userHeadingBoldButton">
              Search
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          height: 470,
          borderRadius: "10px",
          [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
          ...miniScrollBarStyles,
        }}
        id="table-container"
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ backgroundColor: "background.bgLayoutA" }}>
            <TableRow>
              {tableHeader.map((data) => (
                <TableCell
                  key={data.id}
                  sx={{
                    backgroundColor: "background.bgLayoutA",
                    minWidth: data.width,
                  }}
                >
                  <Typography sx={userHeading} variant="userTableHeadingBold">
                    {data.name}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Loader />
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                {/* <TableCell></TableCell> */}
                <TableCell></TableCell>
              </TableRow>
            ) : filteredData?.length < 1 ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell sx={{ minWidth: "250px" }}>
                  <Typography sx={userHeading} variant="userTableCellName">
                    No Application Available
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>

                {/* <TableCell></TableCell> */}
                <TableCell></TableCell>
              </TableRow>
            ) : (
              filteredData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow
                    hover
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                    onClick={() => handleApplicantRowClick(row)}
                  >
                    <TableCell
                      sx={{ ...styledTableCell, minWidth: "100px" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={userHeading}
                        variant="userTableCellName"
                        dangerouslySetInnerHTML={{
                          __html: dateTimeApiformat(row.createdAt),
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: 150,
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography sx={userHeading} variant="userTableCellName">
                        {getMunicipalityName(row?.muncipalityId)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: 180,
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography sx={userHeading} variant="userTableCellName">
                        {row?.projectAddress}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: 150,
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={userHeading}
                        variant="userTableCellDescription"
                      >
                        {row.jobDescription.length > 58
                          ? row.jobDescription.substring(0, 58)
                          : row.jobDescription}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: 150,
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography sx={userHeading} variant="userTableCellName">
                        {row.ownerName}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: 150,
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          ...userHeading,
                          color:
                            row?.paymentDone === null ||
                            row?.paymentDone === false
                              ? "red"
                              : "primary.tableFontColor",
                        }}
                        variant="userTableCellPermitFee"
                      >
                        ${row?.totalAmount}
                      </Typography>
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: 220,
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography sx={userHeading} variant="userTableCellName">
                        {row?.paymentDone === null || row?.paymentDone === false
                          ? "Unpaid"
                          : "Paid"}
                      </Typography>
                    </TableCell> */}

                    <TableCell
                      sx={{
                        ...styledTableCell,
                        minWidth: 200,
                      }}
                      component="th"
                      scope="row"
                    >
                      <Typography sx={userHeading} variant="userTableCellName">
                        {row?.status}
                      </Typography>
                    </TableCell>
                  </StyledTableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        setPage={handleChangePage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        filteredList={filteredData}
        setRowsPerPage={handleRowsPerPageChange}
      />
    </Box>
  );
};

export default Index;

// import React, { useContext, useEffect, useRef, useState } from "react";
// import {
//   Box,
//   Grid,
//   Paper,
//   Table,
//   Button,
//   TableRow,
//   TableHead,
//   TableBody,
//   TableCell,
//   InputBase,
//   Typography,
//   TableContainer,
//   Autocomplete,
//   TextField,
// } from "@mui/material";
// import Loader from "../Loader/Loader";
// import { Search } from "@mui/icons-material";
// import CustomPagination from "../../hooks/useTablePagination";
// import {
//   StyledTableRow,
//   autoCompleteStyle,
//   miniScrollBarStyles,
//   styledTableCell,
//   textfield,
//   userHeading,
// } from "../../utils/muiComponentStyles";

// import { useNavigate } from "react-router-dom";
// import ToasterContext from "../../utils/context/toasterContext";
// import { dispatch, useSelector } from "../../redux/store";
// import { getAllMuncipalities } from "../../redux/slices/muncipalitySlice";

// const Index = ({ tableHeader, bodyData, loading }) => {
//   const navigate = useNavigate();
//   const [page, setPage] = useState(0);
//   const tableRef = useRef(null);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [dateTimeObj, setDateTimeObj] = useState({});
//   const [filteredList, setFilteredList] = useState([]);
//   const [toBeSearched, setToBeSearched] = useState("");
//   const fireToasterContext = useContext(ToasterContext);
//   const [dateError, setDateError] = useState({
//     startDate: false,
//     endDate: false,
//   });

//   const [appStatus, setAppStatus] = useState("");
//   const [paymentStatus, setPaymentStatus] = useState("");
//   const statuses = [
//     "All",
//     "New Application",
//     "Pending Approval",
//     "Approved",
//     "Waiting for Payment",
//     "Void/Rejected",
//   ];

//   let { muncipalityList } = useSelector((state) => state.muncipalityReducer);
//   useEffect(() => {
//     const fetchDataAndApplyFilters = async () => {
//       try {
//         // Dispatch the action to fetch municipalities
//         await dispatch(getAllMuncipalities());

//         // Get filters from localStorage
//         const savedSearchFilter = localStorage.getItem("searchFilter");
//         const savedAppStatusFilter = localStorage.getItem(
//           "applicationStatusFilter"
//         );
//         const savedPaymentStatusFilter = localStorage.getItem(
//           "paymentStatusFilter"
//         );
//         const savedStartDate = localStorage.getItem("startDate");
//         const savedEndDate = localStorage.getItem("endDate");
//         const savedRowsPerPage = localStorage.getItem("rowsPerPageDashboard");
//         const savedPage = localStorage.getItem("pageDashboard");

//         // Apply saved filters if available
//         if (savedSearchFilter) setToBeSearched(savedSearchFilter);
//         if (savedAppStatusFilter)
//           handleAutoCompleteOnChange(null, savedAppStatusFilter);
//         if (savedPaymentStatusFilter)
//           handlePaymentStatusChange(null, savedPaymentStatusFilter);

//         // If startDate or endDate exists in localStorage, set it in state
//         if (savedStartDate || savedEndDate) {
//           setDateTimeObj({
//             startDate: savedStartDate || "",
//             endDate: savedEndDate || "",
//           });
//           handleDateSearch(); // Apply date filter if saved
//           setPage(0);
//         }
//         if (savedRowsPerPage) setRowsPerPage(Number(savedRowsPerPage));
//         if (savedPage) setPage(Number(savedPage));
//       } catch (error) {
//         console.error("Error fetching municipalities data", error);
//       }
//     };

//     // Fetch data and apply filters when the component mounts
//     fetchDataAndApplyFilters();
//   }, [bodyData, dispatch]);

//   // Fetch data and apply filters on mount
//   // useEffect(() => {
//   //   const fetchDataAndApplyFilters = async () => {
//   //     try {
//   //       await dispatch(getAllMuncipalities());

//   //       // Initialize from localStorage
//   //       const savedRowsPerPage = localStorage.getItem("rowsPerPageDashboard");
//   //       const savedPage = localStorage.getItem("pageDashboard");

//   //       if (savedRowsPerPage) setRowsPerPage(Number(savedRowsPerPage));
//   //       if (savedPage) setPage(Number(savedPage));

//   //       // Other localStorage fetching logic...
//   //     } catch (error) {
//   //       console.error("Error fetching municipalities data", error);
//   //     }
//   //   };

//   //   fetchDataAndApplyFilters();
//   // }, [bodyData, dispatch]);

//   // Update filtered list when bodyData changes
//   useEffect(() => {
//     if (filteredList && filteredList.length > 0) {
//       setFilteredList(filteredList);
//     } else {
//       setFilteredList(bodyData); // Ensure filteredList is initialized with bodyData
//     }
//   }, [bodyData]);

//   // Scroll to top when page changes
//   useEffect(() => {
//     if (page !== 0) {
//       tableRef.current?.scrollIntoView({ behavior: "smooth" });
//     }
//   }, [page]);

//   // const handleSearch = (value) => {
//   //   setPage(0);
//   //   setToBeSearched(value);
//   //   localStorage.setItem("searchFilter", value); // Save search filter
//   // };
//   const handleSearch = (value) => {
//     setPage(0);
//     setToBeSearched(value);
//     localStorage.setItem("searchFilter", value);
//     setFilteredList(handleFilter(bodyData, value)); // Ensure you filter directly from bodyData
//   };
//   const handleAutoCompleteOnChange = (event, newValue) => {
//     setPage(0);
//     localStorage.setItem("applicationStatusFilter", newValue || "All");
//     setAppStatus(newValue || "All");

//     const filteredData = bodyData.filter((el) => {
//       return newValue === "All" || el.status === newValue;
//     });
//     setFilteredList(handleFilter(filteredData, toBeSearched)); // Combine search and status filters
//   };

//   const handlePaymentStatusChange = (event, newValue) => {
//     setPage(0);
//     localStorage.setItem("paymentStatusFilter", newValue || "");
//     setPaymentStatus(newValue || "");

//     const filteredData = bodyData.filter((row) => {
//       if (newValue === "Paid") return row.paymentDone === true;
//       if (newValue === "Unpaid")
//         return row.paymentDone === false || row.paymentDone === null;
//       return true;
//     });
//     setFilteredList(handleFilter(filteredData, toBeSearched)); // Combine search and payment filters
//   };

//   // const handleAutoCompleteOnChange = (event, newValue) => {
//   //   setPage(0);
//   //   localStorage.setItem("applicationStatusFilter", newValue || "All");
//   //   setAppStatus(newValue || "All");

//   //   if (newValue === null || newValue === "All") {
//   //     setFilteredList(bodyData);
//   //   } else {
//   //     const filteredData = bodyData.filter((el) => el.status === newValue);
//   //     setFilteredList(filteredData);
//   //   }
//   // };

//   // const handlePaymentStatusChange = (event, newValue) => {
//   //   setPage(0);
//   //   localStorage.setItem("paymentStatusFilter", newValue || "");
//   //   setPaymentStatus(newValue || "");

//   //   const filteredData = bodyData.filter((row) => {
//   //     if (newValue === "Paid") return row.paymentDone === true;
//   //     if (newValue === "Unpaid")
//   //       return row.paymentDone === false || row.paymentDone === null;
//   //     return bodyData; // If "All" or null, return the original data
//   //   });
//   //   setFilteredList(filteredData);
//   // };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage - 1);
//     localStorage.setItem("pageDashboard", newPage - 1); // Persist page in localStorage
//   };

//   const handleRowsPerPageChange = (newRowsPerPage) => {
//     setRowsPerPage(newRowsPerPage);
//     localStorage.setItem("rowsPerPageDashboard", newRowsPerPage); // Persist rowsPerPage in localStorage
//     setPage(0); // Reset page to 0 when changing rows per page
//     localStorage.setItem("pageDashboard", 0); // Persist the page reset to localStorage
//   };

//   const handleFilter = (bodyData, searchTerm) => {
//     const lowerCaseSearchTerm = searchTerm.toLowerCase();

//     return bodyData?.filter((application) => {
//       const {
//         muncipalityId,
//         permitNumber,
//         projectAddress,
//         jobDescription,
//         ownerName,
//       } = application;
//       const municipalityName =
//         muncipalityList.find((item) => item.id === muncipalityId)?.name || "";
//       return (
//         municipalityName?.toLowerCase().includes(lowerCaseSearchTerm) ||
//         permitNumber?.toLowerCase().includes(lowerCaseSearchTerm) ||
//         projectAddress?.toLowerCase().includes(lowerCaseSearchTerm) ||
//         ownerName?.toLowerCase().includes(lowerCaseSearchTerm) ||
//         jobDescription?.toLowerCase().includes(lowerCaseSearchTerm)
//       );
//     });
//   };

//   const filteredData = handleFilter(filteredList, toBeSearched);

//   const handleDateChange = (event) => {
//     setPage(0);

//     // Update the dateTimeObj state with the new date value
//     const tempdateTimeObj = { ...dateTimeObj };
//     tempdateTimeObj[event.target.name] = event.target.value;
//     setDateTimeObj(tempdateTimeObj);

//     // Persist the selected date in localStorage
//     if (event.target.name === "startDate") {
//       localStorage.setItem("startDate", event.target.value);
//     } else if (event.target.name === "endDate") {
//       localStorage.setItem("endDate", event.target.value);
//     }
//   };

//   // const handleDateSearch = () => {
//   //   // Retrieve dates from localStorage if not available in state
//   //   const savedStartDate = localStorage.getItem("startDate");
//   //   const savedEndDate = localStorage.getItem("endDate");

//   //   const startDate = dateTimeObj.startDate || savedStartDate;
//   //   const endDate = dateTimeObj.endDate || savedEndDate;

//   //   // If neither start date nor end date is selected, reset the data and clear any errors
//   //   if (!startDate && !endDate) {
//   //     setDateError({
//   //       startDate: false,
//   //       endDate: false,
//   //     });
//   //     setFilteredList(bodyData); // Reset data to show all records
//   //     return;
//   //   }

//   //   // If only one date is selected, show an error and do not filter
//   //   if (!startDate || !endDate) {
//   //     setDateError({
//   //       startDate: !startDate,
//   //       endDate: !endDate,
//   //     });
//   //     fireToasterContext.fireToasterHandler(
//   //       false,
//   //       "Please select both start and end dates"
//   //     );
//   //     return;
//   //   }

//   //   // If both dates are selected, proceed with filtering
//   //   const filteredApplicants = bodyData.filter((u) => {
//   //     // Filter by date (ignoring time)
//   //     const startDateMatch = u.createdAt.substring(0, 10) >= startDate;
//   //     const endDateMatch = u.createdAt.substring(0, 10) <= endDate;
//   //     return startDateMatch && endDateMatch;
//   //   });

//   //   // Update the filtered data state
//   //   setFilteredList(filteredApplicants);

//   //   // Clear date errors after successful search
//   //   setDateError({
//   //     startDate: false,
//   //     endDate: false,
//   //   });
//   // };
//   const handleDateSearch = () => {
//     // Retrieve dates from localStorage if not available in state
//     const savedStartDate = localStorage.getItem("startDate");
//     const savedEndDate = localStorage.getItem("endDate");

//     const startDate = dateTimeObj.startDate || savedStartDate;
//     const endDate = dateTimeObj.endDate || savedEndDate;

//     // If both dates are empty, apply other filters (search, app status, payment status)
//     if (!startDate && !endDate) {
//       setDateError({
//         startDate: false,
//         endDate: false,
//       });

//       // Apply other filters like search, application status, and payment status
//       let filteredData = bodyData;

//       if (appStatus && appStatus !== "All") {
//         filteredData = filteredData.filter((el) => el.status === appStatus);
//       }

//       if (paymentStatus) {
//         filteredData = filteredData.filter((row) => {
//           if (paymentStatus === "Paid") return row.paymentDone === true;
//           if (paymentStatus === "Unpaid")
//             return row.paymentDone === false || row.paymentDone === null;
//           return true;
//         });
//       }

//       filteredData = handleFilter(filteredData, toBeSearched);

//       setFilteredList(filteredData); // Update the filtered list with other filters
//       return;
//     }

//     // If only one date is selected, show an error and do not filter
//     if (!startDate || !endDate) {
//       setDateError({
//         startDate: !startDate,
//         endDate: !endDate,
//       });
//       fireToasterContext.fireToasterHandler(
//         false,
//         "Please select both start and end dates"
//       );
//       return;
//     }

//     // If both dates are selected, proceed with filtering by date
//     const filteredApplicants = bodyData.filter((u) => {
//       const startDateMatch = u.createdAt.substring(0, 10) >= startDate;
//       const endDateMatch = u.createdAt.substring(0, 10) <= endDate;
//       return startDateMatch && endDateMatch;
//     });

//     // Apply other filters like search, application status, and payment status
//     let finalFilteredData = filteredApplicants;

//     if (appStatus && appStatus !== "All") {
//       finalFilteredData = finalFilteredData.filter(
//         (el) => el.status === appStatus
//       );
//     }

//     if (paymentStatus) {
//       finalFilteredData = finalFilteredData.filter((row) => {
//         if (paymentStatus === "Paid") return row.paymentDone === true;
//         if (paymentStatus === "Unpaid")
//           return row.paymentDone === false || row.paymentDone === null;
//         return true;
//       });
//     }

//     finalFilteredData = handleFilter(finalFilteredData, toBeSearched);

//     // Update the filtered list
//     setFilteredList(finalFilteredData);

//     // Clear date errors after successful search
//     setDateError({
//       startDate: false,
//       endDate: false,
//     });
//   };

//   const dateTimeApiformat = (currentDate) => {
//     const [datePart, timePart] = currentDate.split("T");
//     const [yyyy, mm, dd] = datePart.split("-");
//     const [hh, min] = timePart.split(":");
//     const isAM = parseInt(hh, 10) < 12;
//     const formattedDate = `${mm}/${dd}/${yyyy}`;
//     const formattedTime = `${hh % 12 || 12}:${min} ${isAM ? "AM" : "PM"}`;

//     return `${formattedDate} || ${formattedTime}`;
//   };

//   const handleApplicantRowClick = (data) => {
//     localStorage.setItem("selectedApplication", JSON.stringify(data));
//     navigate("/applications/application-detail");
//   };

//   const getMunicipalityName = (id) => {
//     for (let i = 0; i < muncipalityList.length; i++) {
//       if (muncipalityList[i].id == id) {
//         return muncipalityList[i].name;
//       }
//     }
//   };
//   return (
//     <Box>
//       <div ref={tableRef} />
//       <Grid spacing={2} container sx={{ my: 1 }}>
//         <Grid item xs={6} md={2.5}>
//           <InputBase
//             value={toBeSearched}
//             disabled={loading}
//             onChange={(e) => handleSearch(e.target.value)}
//             sx={{
//               width: "100%",
//               height: "38px",
//               border: "1px solid rgba(169, 169, 169, 0.6)",
//               borderRadius: 2,
//               backgroundColor: "background.bgSearchBar",
//               fontFamily: "Poppins",
//             }}
//             placeholder="Search"
//             inputProps={{ "aria-label": "search" }}
//             startAdornment={
//               <Search
//                 sx={{
//                   color: "rgba(151, 151, 151, 0.68);",
//                   paddingLeft: "3px",
//                 }}
//               />
//             }
//           />
//         </Grid>

//         <Grid item xs={6} md={2}>
//           <Autocomplete
//             disabled={loading}
//             disablePortal
//             id="application-status-autocomplete"
//             options={statuses}
//             value={appStatus} // Set the value to the state variable
//             onChange={handleAutoCompleteOnChange}
//             sx={{ fontFamily: "Poppins" }}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 label="Application Status"
//                 size="small"
//                 sx={autoCompleteStyle}
//                 InputLabelProps={{
//                   style: { fontFamily: "Poppins" },
//                 }}
//               />
//             )}
//           />
//         </Grid>
//         <Grid item xs={6} md={2}>
//           <Autocomplete
//             disabled={loading}
//             disablePortal
//             id="payment-status-autocomplete"
//             options={["Paid", "Unpaid"]}
//             value={paymentStatus} // Set the value to the state variable
//             onChange={handlePaymentStatusChange}
//             sx={{ fontFamily: "Poppins" }}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 label="Payment Status"
//                 size="small"
//                 sx={autoCompleteStyle}
//                 InputLabelProps={{
//                   style: { fontFamily: "Poppins" },
//                 }}
//               />
//             )}
//           />
//         </Grid>

//         <Grid item xs={6} md={2}>
//           <TextField
//             disabled={loading}
//             size="small"
//             id="startDate"
//             label="Start Date"
//             name="startDate"
//             type="date"
//             value={dateTimeObj.startDate || ""} // Persist the value in the input field
//             onChange={handleDateChange}
//             inputProps={{
//               max: new Date().toISOString().split("T")[0],
//             }}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             sx={{ ...textfield, backgroundColor: "background.bgSearchBar" }}
//             error={dateError.startDate}
//           />
//         </Grid>
//         <Grid item xs={6} md={2}>
//           <TextField
//             disabled={loading}
//             size="small"
//             id="endDate"
//             label="End Date"
//             name="endDate"
//             type="date"
//             value={dateTimeObj.endDate || ""} // Persist the value in the input field
//             onChange={handleDateChange}
//             inputProps={{
//               max: new Date().toISOString().split("T")[0],
//             }}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             sx={{ ...textfield, backgroundColor: "background.bgSearchBar" }}
//             error={dateError.endDate}
//           />
//         </Grid>

//         <Grid item xs={6} md={1.5}>
//           <Button
//             onClick={handleDateSearch}
//             disableRipple
//             disabled={loading}
//             sx={{
//               backgroundColor: "background.bgLayoutB",
//               width: "100%",
//               borderRadius: "10px",
//               "&:hover": { backgroundColor: "background.bgLayoutB" },
//               "&:disabled": {
//                 backgroundColor: "rgba(224, 224, 224, 1)",
//               },
//             }}
//           >
//             <Typography sx={userHeading} variant="userHeadingBoldButton">
//               Search
//             </Typography>
//           </Button>
//         </Grid>
//       </Grid>

//       <TableContainer
//         component={Paper}
//         elevation={0}
//         sx={{
//           height: 470,
//           borderRadius: "10px",
//           [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
//           ...miniScrollBarStyles,
//         }}
//         id="table-container"
//       >
//         <Table stickyHeader aria-label="sticky table">
//           <TableHead sx={{ backgroundColor: "background.bgLayoutA" }}>
//             <TableRow>
//               {tableHeader.map((data) => (
//                 <TableCell
//                   key={data.id}
//                   sx={{
//                     backgroundColor: "background.bgLayoutA",
//                     minWidth: data.width,
//                   }}
//                 >
//                   <Typography sx={userHeading} variant="userTableHeadingBold">
//                     {data.name}
//                   </Typography>
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>

//           <TableBody>
//             {loading ? (
//               <TableRow>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell>
//                   <Loader />
//                 </TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 {/* <TableCell></TableCell> */}
//                 <TableCell></TableCell>
//               </TableRow>
//             ) : filteredData?.length < 1 ? (
//               <TableRow>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell sx={{ minWidth: "250px" }}>
//                   <Typography sx={userHeading} variant="userTableCellName">
//                     No Application Available
//                   </Typography>
//                 </TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>

//                 {/* <TableCell></TableCell> */}
//                 <TableCell></TableCell>
//               </TableRow>
//             ) : (
//               filteredData
//                 ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                 .map((row) => (
//                   <StyledTableRow
//                     hover
//                     key={row.id}
//                     sx={{
//                       "&:last-child td, &:last-child th": { border: 0 },
//                       cursor: "pointer",
//                     }}
//                     onClick={() => handleApplicantRowClick(row)}
//                   >
//                     <TableCell
//                       sx={{ ...styledTableCell, minWidth: "100px" }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={userHeading}
//                         variant="userTableCellName"
//                         dangerouslySetInnerHTML={{
//                           __html: dateTimeApiformat(row.createdAt),
//                         }}
//                       />
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: 150,
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography sx={userHeading} variant="userTableCellName">
//                         {getMunicipalityName(row?.muncipalityId)}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: 180,
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography sx={userHeading} variant="userTableCellName">
//                         {row?.projectAddress}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: 150,
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={userHeading}
//                         variant="userTableCellDescription"
//                       >
//                         {row.jobDescription.length > 58
//                           ? row.jobDescription.substring(0, 58)
//                           : row.jobDescription}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: 150,
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography sx={userHeading} variant="userTableCellName">
//                         {row.ownerName}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: 150,
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={{
//                           ...userHeading,
//                           color:
//                             row?.paymentDone === null ||
//                             row?.paymentDone === false
//                               ? "red"
//                               : "primary.tableFontColor",
//                         }}
//                         variant="userTableCellPermitFee"
//                       >
//                         ${row?.totalAmount}
//                       </Typography>
//                     </TableCell>
//                     {/* <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: 220,
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography sx={userHeading} variant="userTableCellName">
//                         {row?.paymentDone === null || row?.paymentDone === false
//                           ? "Unpaid"
//                           : "Paid"}
//                       </Typography>
//                     </TableCell> */}

//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: 200,
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography sx={userHeading} variant="userTableCellName">
//                         {row?.status}
//                       </Typography>
//                     </TableCell>
//                   </StyledTableRow>
//                 ))
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <CustomPagination
//         page={page}
//         setPage={handleChangePage}
//         rowsPerPage={rowsPerPage}
//         handleChangePage={handleChangePage}
//         filteredList={filteredData}
//         setRowsPerPage={handleRowsPerPageChange}
//       />
//     </Box>
//   );
// };

// export default Index;

// import React, { useContext, useEffect, useRef, useState } from "react";
// import {
//   Box,
//   Grid,
//   Paper,
//   Table,
//   Button,
//   TableRow,
//   TableHead,
//   TableBody,
//   TableCell,
//   InputBase,
//   Typography,
//   TableContainer,
//   Autocomplete,
//   TextField,
// } from "@mui/material";
// import Loader from "../Loader/Loader";
// import { Search } from "@mui/icons-material";
// import CustomPagination from "../../hooks/useTablePagination";
// import {
//   StyledTableRow,
//   autoCompleteStyle,
//   miniScrollBarStyles,
//   styledTableCell,
//   textfield,
//   // tableLoaderBox,
//   userHeading,
// } from "../../utils/muiComponentStyles";

// import { useNavigate } from "react-router-dom";
// import ToasterContext from "../../utils/context/toasterContext";
// import { dispatch, useSelector } from "../../redux/store";
// import { getAllMuncipalities } from "../../redux/slices/muncipalitySlice";

// const Index = ({ tableHeader, bodyData, loading }) => {
//   const navigate = useNavigate();
//   const [page, setPage] = useState(0);
//   const tableRef = useRef(null);
//   const [rowsPerPage, setRowsPerPage] = useState(50);
//   const [dateTimeObj, setDateTimeObj] = useState({});
//   const [filteredList, setFilteredList] = useState([]);
//   const [toBeSearched, setToBeSearched] = useState("");
//   const fireToasterContext = useContext(ToasterContext);
//   const [dateError, setDateError] = useState({
//     startDate: false,
//     endDate: false,
//   });
//   // const statuses = ["All", "Pending", "Approved", "Waiting for Payment"];
//   const statuses = [
//     "All",
//     "New Application",
//     "Pending Approval",
//     "Approved",
//     "Waiting for Payment",
//     "Void/Rejected",
//   ];

//   let { muncipalityList } = useSelector((state) => state.muncipalityReducer);

//   useEffect(() => {
//     setFilteredList(bodyData);
//     dispatch(getAllMuncipalities());
//     if (!dateTimeObj.startDate && !dateTimeObj.endDate) {
//       setFilteredList(bodyData);

//       return;
//     }
//   }, [bodyData, dateTimeObj]);
//   useEffect(() => {
//     if (page !== 0) {
//       tableRef.current?.scrollIntoView({ behavior: "smooth" });
//     }
//   }, [page]);

//   const handleSearch = (value) => {
//     setPage(0);
//     setToBeSearched(value);
//   };

//   const handleAutoCompleteOnChange = (event, newValue) => {
//     // setAutoCompleteSearch(newValue);
//     if (newValue === null || newValue === undefined || newValue === "All") {
//       // If newValue is null or undefined, show all data
//       setFilteredList(bodyData);
//     } else {
//       // Filter the bodyData array based on the selected status
//       const filteredData = bodyData.filter((el) => el.status === newValue);
//       setFilteredList(filteredData); // Set the filtered data to state
//     }
//   };

//   const handlePaymentStatusChange = (event, newValue) => {
//     const filteredData = bodyData.filter((row) => {
//       if (newValue === "Paid") {
//         return row.paymentDone === true;
//       } else if (newValue === "Unpaid") {
//         return row.paymentDone === false || row.paymentDone === null;
//       } else {
//         return filteredList;
//       }
//     });
//     setFilteredList(filteredData);
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage - 1);
//   };

//   const handleFilter = (bodyData, searchTerm) => {
//     const lowerCaseSearchTerm = searchTerm.toLowerCase();

//     return bodyData?.filter((application) => {
//       // Destructure application properties for easier access
//       const {
//         muncipalityId,
//         permitNumber,
//         projectAddress,
//         jobDescription,
//         ownerName,
//       } = application;

//       const municipalityName =
//         muncipalityList.find((item) => item.id === muncipalityId)?.name || "";

//       return (
//         municipalityName?.toLowerCase().includes(lowerCaseSearchTerm) ||
//         permitNumber?.toLowerCase().includes(lowerCaseSearchTerm) ||
//         projectAddress?.toLowerCase().includes(lowerCaseSearchTerm) ||
//         ownerName?.toLowerCase().includes(lowerCaseSearchTerm) ||
//         jobDescription?.toLowerCase().includes(lowerCaseSearchTerm)
//       );
//     });
//   };

//   // Filter the original list of users (filteredList) based on the search term (toBeSearched)
//   const filteredData = handleFilter(filteredList, toBeSearched);

//   const handleDateChange = (event) => {
//     let tempdateTimeObj = { ...dateTimeObj };
//     tempdateTimeObj[event.target.name] = event.target.value;
//     setDateTimeObj(tempdateTimeObj);
//   };

//   const handleDateSearch = () => {
//     if (!dateTimeObj.startDate || !dateTimeObj.endDate) {
//       setDateError({
//         startDate: true,
//         endDate: true,
//       });
//       fireToasterContext.fireToasterHandler(false, "Please select date first");
//       return;
//     }
//     const filteredApplicants = bodyData.filter((u) => {
//       // Filter by date (without considering the time)
//       const startDateMatch =
//         !dateTimeObj.startDate ||
//         u.createdAt.substring(0, 10) >= dateTimeObj.startDate;
//       const endDateMatch =
//         !dateTimeObj.endDate ||
//         u.createdAt.substring(0, 10) <= dateTimeObj.endDate;
//       return startDateMatch && endDateMatch;
//     });

//     // Update the filtered data state when the Search button is clicked
//     setFilteredList(filteredApplicants);
//   };

//   const dateTimeApiformat = (currentDate) => {
//     // Split the input date into date and time components
//     const [datePart, timePart] = currentDate.split("T");
//     // Extract date components (year, month, day)
//     const [yyyy, mm, dd] = datePart.split("-");
//     // Extract time components (hours, minutes)
//     const [hh, min] = timePart.split(":");
//     // Determine whether it's AM or PM based on the hour
//     const isAM = parseInt(hh, 10) < 12;
//     // Format the date
//     const formattedDate = `${mm}/${dd}/${yyyy}`;
//     // Format the time in 12-hour format with AM/PM
//     const formattedTime = `${hh % 12 || 12}:${min} ${isAM ? "AM" : "PM"}`;

//     return `${formattedDate} || ${formattedTime}`;
//   };
//   const handleApplicantRowClick = (data) => {
//     localStorage.setItem("selectedApplication", JSON.stringify(data));
//     navigate("/applications/application-detail");
//   };

//   const getMunicipalityName = (id) => {
//     for (let i = 0; i < muncipalityList.length; i++) {
//       if (muncipalityList[i].id == id) {
//         return muncipalityList[i].name;
//       }
//     }
//   };

//   return (
//     <Box>
//       <div ref={tableRef} />
//       <Grid spacing={2} container sx={{ my: 1 }}>
//         <Grid item xs={12} md={2.5}>
//           <InputBase
//             disabled={loading}
//             onChange={(e) => handleSearch(e.target.value)}
//             sx={{
//               width: "100%",
//               height: "38px",
//               border: "1px solid rgba(169, 169, 169, 0.6)",
//               borderRadius: 2,
//               backgroundColor: "background.bgSearchBar",
//               fontFamily: "Poppins",
//             }}
//             placeholder="Search"
//             inputProps={{ "aria-label": "search" }}
//             startAdornment={
//               <Search
//                 sx={{
//                   color: "rgba(151, 151, 151, 0.68);",
//                   paddingLeft: "3px",
//                 }}
//               />
//             }
//           />
//         </Grid>

//         <Grid item xs={6} md={2}>
//           <Autocomplete
//             disabled={loading}
//             disablePortal
//             id="combo-box-demo"
//             options={statuses}
//             sx={{ fontFamily: "Poppins" }}
//             getOptionLabel={(option) => option}
//             onChange={(event, newValue) =>
//               handleAutoCompleteOnChange(event, newValue)
//             }
//             // value="All"
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 label="Application Status"
//                 size="small"
//                 sx={autoCompleteStyle}
//                 InputLabelProps={{
//                   style: { fontFamily: "Poppins" },
//                 }}
//               />
//             )}
//             renderOption={(props, option) => (
//               <li {...props} key={option.id}>
//                 <Typography
//                   sx={{
//                     fontFamily: "Poppins",
//                     fontSize: "15px",
//                     color: "#404041",
//                   }}
//                 >
//                   {option}
//                 </Typography>
//               </li>
//             )}
//           />
//         </Grid>
//         <Grid item xs={6} md={2}>
//           <Autocomplete
//             disabled={loading}
//             disablePortal
//             id="combo-box-demo"
//             options={["Paid", "Unpaid"]}
//             sx={{ fontFamily: "Poppins" }}
//             getOptionLabel={(option) => option}
//             onChange={(event, newValue) =>
//               handlePaymentStatusChange(event, newValue)
//             }
//             // value="All"
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 label="Payment Status"
//                 size="small"
//                 sx={autoCompleteStyle}
//                 InputLabelProps={{
//                   style: { fontFamily: "Poppins" },
//                 }}
//               />
//             )}
//             renderOption={(props, option) => (
//               <li {...props} key={option.id}>
//                 <Typography
//                   sx={{
//                     fontFamily: "Poppins",
//                     fontSize: "15px",
//                     color: "#404041",
//                   }}
//                 >
//                   {option}
//                 </Typography>
//               </li>
//             )}
//           />
//         </Grid>

//         <Grid item xs={6} md={2}>
//           <TextField
//             disabled={loading}
//             size="small"
//             id="startDate"
//             label="Start Date"
//             name="startDate"
//             type="date"
//             placeholder="Start Date"
//             onChange={handleDateChange}
//             inputProps={{
//               max: new Date().toISOString().split("T")[0],
//             }}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             sx={{ ...textfield, backgroundColor: "background.bgSearchBar" }}
//             error={dateError.startDate}
//           />
//         </Grid>
//         <Grid item xs={6} md={2}>
//           <TextField
//             disabled={loading}
//             size="small"
//             id="startDate"
//             label="End Date"
//             name="endDate"
//             placeholder="End Date"
//             type="date"
//             onChange={handleDateChange}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             sx={{ ...textfield, backgroundColor: "background.bgSearchBar" }}
//             error={dateError.endDate}
//           />
//         </Grid>

//         <Grid item xs={6} md={1.5}>
//           <Button
//             onClick={handleDateSearch}
//             disableRipple
//             disabled={loading}
//             sx={{
//               backgroundColor: "background.bgLayoutB",
//               width: "100%",
//               borderRadius: "10px",
//               "&:hover": { backgroundColor: "background.bgLayoutB" },
//               "&:disabled": {
//                 backgroundColor: "rgba(224, 224, 224, 1)",
//               },
//             }}
//           >
//             <Typography sx={userHeading} variant="userHeadingBoldButton">
//               Search
//             </Typography>
//           </Button>
//         </Grid>
//       </Grid>

//       <TableContainer
//         component={Paper}
//         elevation={0}
//         sx={{
//           height: 470,
//           borderRadius: "10px",
//           [`::-webkit-scrollbar`]: { width: "0px", background: "transparent" },
//           ...miniScrollBarStyles,
//         }}
//         id="table-container"
//       >
//         <Table stickyHeader aria-label="sticky table">
//           <TableHead sx={{ backgroundColor: "background.bgLayoutA" }}>
//             <TableRow>
//               {tableHeader.map((data) => (
//                 <TableCell
//                   key={data.id}
//                   sx={{
//                     backgroundColor: "background.bgLayoutA",
//                     minWidth: data.width,
//                   }}
//                 >
//                   <Typography sx={userHeading} variant="userTableHeadingBold">
//                     {data.name}
//                   </Typography>
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>

//           <TableBody>
//             {loading ? (
//               <TableRow>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell>
//                   <Loader />
//                 </TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 {/* <TableCell></TableCell> */}
//                 <TableCell></TableCell>
//               </TableRow>
//             ) : filteredData?.length < 1 ? (
//               <TableRow>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell sx={{ minWidth: "250px" }}>
//                   <Typography sx={userHeading} variant="userTableCellName">
//                     No Application Available
//                   </Typography>
//                 </TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>
//                 <TableCell></TableCell>

//                 {/* <TableCell></TableCell> */}
//                 <TableCell></TableCell>
//               </TableRow>
//             ) : (
//               filteredData
//                 ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                 .map((row) => (
//                   <StyledTableRow
//                     hover
//                     key={row.id}
//                     sx={{
//                       "&:last-child td, &:last-child th": { border: 0 },
//                       cursor: "pointer",
//                     }}
//                     onClick={() => {
//                       handleApplicantRowClick(row);
//                     }}
//                   >
//                     <TableCell
//                       sx={{ ...styledTableCell, minWidth: "100px" }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={userHeading}
//                         variant="userTableCellName"
//                         dangerouslySetInnerHTML={{
//                           __html: dateTimeApiformat(row.createdAt),
//                         }}
//                       />
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: 150,
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography sx={userHeading} variant="userTableCellName">
//                         {getMunicipalityName(row?.muncipalityId)}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: 180,
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography sx={userHeading} variant="userTableCellName">
//                         {row?.projectAddress}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: 150,
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={userHeading}
//                         variant="userTableCellDescription"
//                       >
//                         {row.jobDescription.length > 58
//                           ? row.jobDescription.substring(0, 58)
//                           : row.jobDescription}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: 150,
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography sx={userHeading} variant="userTableCellName">
//                         {row.ownerName}
//                       </Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: 150,
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography
//                         sx={{
//                           ...userHeading,
//                           color:
//                             row?.paymentDone === null ||
//                             row?.paymentDone === false
//                               ? "red"
//                               : "primary.tableFontColor",
//                         }}
//                         variant="userTableCellPermitFee"
//                       >
//                         ${row?.totalAmount}
//                       </Typography>
//                     </TableCell>
//                     {/* <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: 220,
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography sx={userHeading} variant="userTableCellName">
//                         {row?.paymentDone === null || row?.paymentDone === false
//                           ? "Unpaid"
//                           : "Paid"}
//                       </Typography>
//                     </TableCell> */}

//                     <TableCell
//                       sx={{
//                         ...styledTableCell,
//                         minWidth: 200,
//                       }}
//                       component="th"
//                       scope="row"
//                     >
//                       <Typography sx={userHeading} variant="userTableCellName">
//                         {row?.status}
//                       </Typography>
//                     </TableCell>
//                   </StyledTableRow>
//                 ))
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <CustomPagination
//         page={page}
//         setPage={setPage}
//         rowsPerPage={rowsPerPage}
//         handleChangePage={handleChangePage}
//         filteredList={filteredData}
//         setRowsPerPage={setRowsPerPage}
//       />
//     </Box>
//   );
// };

// export default Index;
