import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Grid,
  CardHeader,
  Box,
  Typography,
  Button,
  TextField,
  Autocomplete,
  CardContent,
  CardActions,
} from "@mui/material";
import {
  applicationCardStyle,
  miniScrollBarStyles,
  recordInspectionTypo,
  textfield,
  textfieldDate,
} from "../../../utils/muiComponentStyles";
import Loader from "../../Loader/Loader";
import {
  createOccupancyLetter,
  createOccupancyPdf,
  getOccupancyLetter,
} from "../../../redux/slices/OccupancyLetterSlice";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "../../../redux/store";
import ToasterContext from "../../../utils/context/toasterContext";
import CircularProgressLoader from "../../../utils/CircularProgressLoader";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import OccupancyLetterPdf from "./OccupancyLetterPdf";
import {
  getApplicantStatus,
  getSingleApplicationData,
  sendEmailToApplicant,
} from "../../../redux/slices/dashboard/applicantsSlice";
import { getAllMuncipalities } from "../../../redux/slices/muncipalitySlice";

const projectTypeList = [
  { id: 1, name: "New Building" },
  { id: 2, name: "Addition" },
  { id: 3, name: "Alteration/Repair" },
  { id: 4, name: "Deck" },
  { id: 5, name: "Demolition" },
  { id: 6, name: "Driveway" },
  { id: 7, name: "Fence" },
  { id: 8, name: "Pool" },
];

const buildingTypeList = [
  { id: 1, name: "Single Family" },
  { id: 2, name: "Duplex" },
  { id: 3, name: "Multi-Family" },
  { id: 4, name: "Commercial-Industrial" },
  { id: 5, name: "Garage/Shed" },
  // { id: 6, name: "Does not apply" },
];

const s3Client = new S3Client({
  endpoint: process.env.REACT_APP_SPACES_ENDPOINT,
  forcePathStyle: true,
  region: process.env.REACT_APP_SPACES_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_SPACES_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SPACES_SECRET_ACCESS_KEY,
  },
});
const Index = () => {
  const dispatch = useDispatch();
  // let application = JSON.parse(localStorage.getItem("selectedApplication"));
  // let loggedInUser = JSON.parse(localStorage.getItem("user"));
  const permissions = localStorage.getItem("permissions");
  const permissionArray = permissions ? permissions.split(",") : [];
  const [manipulationPermission, setManipulationPermission] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [buttonSubmit, setButtonSubmit] = useState(false);
  const fireToasterContext = useContext(ToasterContext);
  const [ownerName, setOwnerName] = useState();
  const [buildingType, setBuildingType] = useState();
  const [occupancyLetterFormObj, setOccupancyLetterFormObj] = useState();
  // const [userRole, setUserRole] = useState();
  // const [userDegigation, setUserDegigation] = useState();
  const [emailError, setEmailError] = useState(false);
  const [pdfButtonEnable, setPdfButtonEnable] = useState(false);
  const [emailButtonLoader, setEmailButtonLoader] = useState(false);
  const [application, setApplication] = useState(
    JSON.parse(localStorage.getItem("selectedApplication"))
  );
  const [descriptionText, setDescriptionText] = useState(
    "This letter shall certify that at the time of issuance, this structure, or portion thereof, was in substantial compliance with the Building Codes of the State of Wisconsin and the Municipality regulating building construction or use. All deficiencies listed below shall be corrected within thirty (30) days unless specifically stated. It is the owner or owner agent's responsibility to contact the Building Inspection Department for re-inspection within said times or be subject to forfeiture or revocation. Inquiries concerning this correspondence may be made to me by the means listed at the bottom of this page. I look forward to working with you to make this code-compliant construction. Thank you in advance for your cooperation."
  );

  let { occupancyLetterList } = useSelector(
    (state) => state.occupancyLetterReducer
  );

  let { muncipalityList } = useSelector((state) => state.muncipalityReducer);

  useEffect(() => {
    if (!permissionArray.includes("manipulate application details")) {
      setManipulationPermission(true);
      setPdfButtonEnable(false);
    } else {
      setManipulationPermission(false);
    }
    dispatch(getOccupancyLetter(application?.id, handleApiResponse));
    setApplication(JSON.parse(localStorage.getItem("selectedApplication")));
    dispatch(getAllMuncipalities());
  }, []);

  useEffect(() => {
    setOccupancyLetterFormObj(occupancyLetterList);
    // setUserRole(
    //   occupancyLetterList.generatedBy === ""
    //     ? loggedInUser?.firstName + " " + loggedInUser?.lastName
    //     : occupancyLetterList.generatedBy
    // );
    // setUserDegigation(
    //   occupancyLetterList.generatedByRole === ""
    //     ? "Building Official"
    //     : occupancyLetterList.generatedByRole
    // );
    setBuildingType(occupancyLetterList?.buildingType);
    setOwnerName(occupancyLetterList?.ownerName);
  }, [occupancyLetterList]);

  const handleApiResponse = (data) => {
    if (data.status) {
      if (data.data.id === null) {
        setPdfButtonEnable(false);
      } else {
        setPdfButtonEnable(true);
      }
      setLoading(false);
      handleUploadPdfToSpaces(data.data);

      // handleUploadPdfToSpaces(data.data);
    } else {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setPdfButtonEnable(false);
    setEmailButtonLoader(false);
    const { id, value } = event.target;
    if (id === "email") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(occupancyLetterFormObj?.email)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }
    setOccupancyLetterFormObj((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };
  // const handlePdfApiRes = (data) => {
  //   if (data.status) {
  //     setPdfButtonEnable(true);
  //   }
  // };
  const handleUploadPdfToSpaces = async (latestData) => {
    let pdfLink;
    const pdfBlob = await pdf(
      <OccupancyLetterPdf data={latestData} />
    ).toBlob();
    const pdfBuffer = await new Response(pdfBlob).arrayBuffer();
    // Upload PDF Buffer to DigitalOcean Spaces
    const params = {
      Bucket: process.env.REACT_APP_SPACES_BUCKET_NAME,
      Key: `${process.env.REACT_APP_ENVIRONMENT}/${
        application?.id
      }/Occupancy-letter-${application?.id}-${Date.now()}.pdf`,
      Body: pdfBuffer,
      ACL: "public-read",
      ContentType: "application/pdf",
    };

    try {
      await s3Client.send(new PutObjectCommand(params));
      pdfLink = `${process.env.REACT_APP_SPACES_ENDPOINT}/${params.Bucket}/${params.Key}`;
      application.occupancyLetterPdfUrl = pdfLink;
      localStorage.setItem("selectedApplication", JSON.stringify(application));
      const pdfObj = {
        applicationId: application.id,
        occupancyLetterPdfUrl: pdfLink,
        applicationDetailPdfUrl: false,
        buildingPlacardPdfUrl: false,
        permitFeePdfUrl: false,
        recordedInspectionPdfUrl: false,
      };
      dispatch(createOccupancyPdf(pdfObj));
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };

  const handleCreateApiRes = async (data) => {
    if (data.status) {
      setTimeout(() => {
        setBtnLoading(false);
        fireToasterContext.fireToasterHandler(true, data.message);
      }, 1500);
      dispatch(getOccupancyLetter(application?.id, handleApiResponse));
      setEmailButtonLoader(false);
      dispatch(getApplicantStatus(application.id));
    } else {
      fireToasterContext.fireToasterHandler(false, data.message);
      setButtonSubmit(false);
      setBtnLoading(false);
      setEmailButtonLoader(false);
    }
  };

  const handleEmailApiResponse = (data) => {
    if (data.status) {
      setEmailButtonLoader(false);
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "Email sent successfully"
      );
      dispatch(getSingleApplicationData(application?.id));
    } else {
      setEmailButtonLoader(false);
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleSendEmailToApplicant = () => {
    if (!pdfButtonEnable) {
      fireToasterContext.fireToasterHandler(
        false,
        "Please save information of occupancy letter first!"
      );
      return;
    }
    setEmailButtonLoader(true);
    let finalData = {
      applicationId: application.id,
      applicationDetail: false,
      permitFee: false,
      buildingPlacard: false,
      occupancyLetter: true,
      recordedInspection: false,
      message: "",
    };
    dispatch(sendEmailToApplicant(finalData, handleEmailApiResponse));
  };

  const handleSave = () => {
    setButtonSubmit(true);
    if (occupancyLetterFormObj.email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(occupancyLetterFormObj?.email)) {
        fireToasterContext.fireToasterHandler(
          false,
          "Please enter a valid email address."
        );
        setEmailError(true);
        return;
      } else {
        setEmailError(false);
      }
    }
    if (
      occupancyLetterFormObj.date &&
      occupancyLetterFormObj.email &&
      occupancyLetterFormObj.phoneNumber &&
      occupancyLetterFormObj.address &&
      occupancyLetterFormObj.projectSite &&
      buildingType &&
      ownerName &&
      descriptionText
    ) {
      setBtnLoading(true);
      let finalObj = {
        applicationId: application.id,
        date: occupancyLetterFormObj.date,
        description: descriptionText,
        // generatedBy: userRole,
        // generatedByRole: userDegigation,
        siteAddress: occupancyLetterFormObj.projectSite,
        address: occupancyLetterFormObj.address,
        email: occupancyLetterFormObj.email,
        phoneNumber: occupancyLetterFormObj.phoneNumber,
        buildingType: buildingType,
        ownerName: ownerName,
      };

      dispatch(createOccupancyLetter(finalObj, handleCreateApiRes));
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please select all required fields"
      );
    }
  };

  return (
    <>
      {/* <PDFViewer style={{ height: "100vh", width: "100%" }}>
        <OccupancyLetterPdf
          data={occupancyLetterList}
          muncipalityList={muncipalityList}
        />
      </PDFViewer> */}
      <Box>
        <Card>
          <CardHeader
            disableTypography
            title={
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>Occupancy Letter Detail</Box>
                <Box>
                  Status: {application?.status} | Permit Fee:{" "}
                  {(application?.totalAmount * 1)?.toFixed(2)}
                </Box>
              </Box>
            }
            sx={applicationCardStyle}
          />

          {loading ? (
            <Box
              sx={{
                height: 180,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                mr: "88px",
              }}
            >
              <Loader />
            </Box>
          ) : (
            <Box component="div" sx={{ my: 3, mx: 3 }}>
              <Typography sx={{ ...recordInspectionTypo, textAlign: "center" }}>
                Permit No: {application?.permitNumber}
              </Typography>

              <Grid
                container
                spacing={2}
                sx={{ mt: 1, wordWrap: "break-word" }}
              >
                <Grid item xs={4} sm={6} md={6} lg={3}>
                  <Typography sx={recordInspectionTypo}>
                    Project Type
                  </Typography>
                  <Typography sx={recordInspectionTypo} variant="subText">
                    {
                      projectTypeList.find(
                        (project) => project.id === application?.projectType
                      )?.name
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Typography sx={recordInspectionTypo}>
                    Project Address
                  </Typography>
                  <Typography sx={recordInspectionTypo} variant="subText">
                    {application?.projectAddress}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Typography sx={recordInspectionTypo}>
                    Applicant Name
                  </Typography>
                  <Typography sx={recordInspectionTypo} variant="subText">
                    {application?.applicantName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Typography sx={recordInspectionTypo}>
                    Applicant Email
                  </Typography>
                  <Typography sx={recordInspectionTypo} variant="subText">
                    {application?.applicantEmail}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          <hr
            style={{
              borderTop: "1px solid #E7E7E7",
            }}
          />
          {/* letter detaiils  */}

          <CardContent
            sx={{
              height: "380px",
              overflowY: "auto",
              mt: 2,
              mx: 1,
              [`::-webkit-scrollbar`]: {
                width: "0px",
                background: "transparent",
              },
              ...miniScrollBarStyles,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="date"
                  name="date"
                  disableUnderline
                  fullWidth
                  sx={textfieldDate}
                  label="Select Date"
                  inputProps={{
                    max: new Date().toISOString().split("T")[0],
                  }}
                  type="date"
                  variant="filled"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: manipulationPermission,
                  }}
                  onChange={handleChange}
                  error={!occupancyLetterFormObj?.date && buttonSubmit}
                  helperText={
                    !occupancyLetterFormObj?.date && buttonSubmit
                      ? "Date is required"
                      : ""
                  }
                  value={occupancyLetterFormObj?.date?.split("T")[0] || ""}
                  onFocus={() =>
                    (document.getElementById("date").type = "date")
                  }
                  onBlur={() => (document.getElementById("date").type = "text")}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="company"
                  name="company"
                  label="Company"
                  variant="outlined"
                  sx={textfield}
                  onChange={handleChange}
                  error={!occupancyLetterFormObj?.company && buttonSubmit}
                  helperText={
                    !occupancyLetterFormObj?.company && buttonSubmit
                      ? "Company name is required"
                      : ""
                  }
                  value={occupancyLetterFormObj?.company || ""}
                  InputProps={{ readOnly: manipulationPermission }}
                />
              </Grid> */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="ownerName"
                  name="ownerName"
                  label="Owner Name"
                  variant="outlined"
                  sx={textfield}
                  onChange={(event) => setOwnerName(event.target.value)}
                  value={ownerName || ""}
                  error={!ownerName && buttonSubmit}
                  helperText={
                    !ownerName && buttonSubmit ? "Owner name is required" : ""
                  }
                  InputProps={{ readOnly: manipulationPermission }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  type="email"
                  sx={textfield}
                  onChange={handleChange}
                  value={occupancyLetterFormObj?.email || ""}
                  error={
                    !!emailError ||
                    (!occupancyLetterFormObj?.email && buttonSubmit)
                  }
                  helperText={
                    (emailError && emailError) ||
                    (!occupancyLetterFormObj?.email && buttonSubmit)
                      ? "Please enter a valid email address"
                      : ""
                  }
                  InputProps={{ readOnly: manipulationPermission }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="phoneNumber"
                  label="Phone Number"
                  variant="outlined"
                  name="phoneNumber"
                  sx={textfield}
                  onChange={handleChange}
                  value={
                    occupancyLetterFormObj?.phoneNumber
                      ? occupancyLetterFormObj?.phoneNumber
                      : ""
                  }
                  error={!occupancyLetterFormObj?.phoneNumber && buttonSubmit}
                  helperText={
                    !occupancyLetterFormObj?.phoneNumber && buttonSubmit
                      ? "Phone number is required"
                      : ""
                  }
                  InputProps={{ readOnly: manipulationPermission }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="permitNumber"
                  label="Permit Number"
                  name="permitNumber"
                  variant="outlined"
                  sx={textfield}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={application?.permitNumber}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="address"
                  label="Address"
                  name="address"
                  variant="outlined"
                  sx={textfield}
                  onChange={handleChange}
                  value={occupancyLetterFormObj?.address || ""}
                  error={!occupancyLetterFormObj?.address && buttonSubmit}
                  helperText={
                    !occupancyLetterFormObj?.address && buttonSubmit
                      ? "Address is required"
                      : ""
                  }
                  InputProps={{ readOnly: manipulationPermission }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="projectSite"
                  name="projectSite"
                  label="Project Site"
                  variant="outlined"
                  sx={textfield}
                  onChange={handleChange}
                  value={occupancyLetterFormObj?.projectSite || ""}
                  error={!occupancyLetterFormObj?.projectSite && buttonSubmit}
                  helperText={
                    !occupancyLetterFormObj?.projectSite && buttonSubmit
                      ? "Site address is required"
                      : ""
                  }
                  InputProps={{ readOnly: manipulationPermission }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Autocomplete
                  id="buildingType"
                  readOnly={manipulationPermission}
                  label="Select Building Type"
                  variant="outlined"
                  name="buildingType"
                  options={buildingTypeList}
                  autoHighlight
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={textfield}
                      error={!buildingType && buttonSubmit}
                      label="Building Type"
                      helperText={
                        !buildingType && buttonSubmit
                          ? "Budilding type is required"
                          : ""
                      }
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Typography
                        sx={{
                          color: "black",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          textTransform: "capitalize",
                        }}
                        variant="inputOutputList"
                      >
                        {option.name}
                      </Typography>
                    </li>
                  )}
                  onChange={(event, newValue) => {
                    setPdfButtonEnable(false);
                    setBuildingType(newValue ? newValue.id : null);
                  }}
                  value={
                    buildingTypeList.find(
                      (option) => option.id == buildingType
                    ) || ""
                  }
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="generatedBy"
                  label="Generated By"
                  variant="outlined"
                  name="generatedBy"
                  sx={textfield}
                  onChange={(event) => setUserRole(event.target.value)}
                  value={userRole}
                  error={!userRole && buttonSubmit}
                  helperText={
                    !userRole && buttonSubmit ? "Generated by is required" : ""
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ readOnly: manipulationPermission }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="generatedByRole"
                  label="Designation"
                  variant="outlined"
                  name="generatedByRole"
                  sx={textfield}
                  onChange={(event) => setUserDegigation(event.target.value)}
                  value={userDegigation}
                  error={!userDegigation && buttonSubmit}
                  helperText={
                    !userDegigation && buttonSubmit
                      ? "Designation is required"
                      : ""
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ readOnly: manipulationPermission }}
                />
              </Grid> */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="description"
                  label="Description"
                  variant="outlined"
                  name="description"
                  multiline
                  rows={6}
                  sx={textfield}
                  onChange={(event) => setDescriptionText(event.target.value)}
                  // value={occupancyLetterFormObj?.description || ""}
                  value={descriptionText}
                  error={!descriptionText && buttonSubmit}
                  helperText={
                    !descriptionText && buttonSubmit
                      ? "Description is required"
                      : ""
                  }
                  InputProps={{ readOnly: manipulationPermission }}
                />
              </Grid>
            </Grid>
          </CardContent>

          <CardActions
            sx={{
              mt: 2,
              ml: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "10px",
              "& > :not(:first-of-type)": {
                m: 0,
              },
              flexWrap: "wrap",
              [`@media screen and (max-width: 1024px)`]: {
                justifyContent: "center",
              },
            }}
          >
            {/* email button */}

            {pdfButtonEnable && pdfButtonEnable ? (
              <Button
                variant="contained"
                sx={{
                  width: "286px",
                  height: "46px",
                  fontFamily: "Poppins-Bold",
                  backgroundColor: "#32363F",
                  borderRadius: "10px",
                  color: "white",
                  [`@media screen and (max-width: 1024px)`]: {
                    width: "100%",
                  },
                  "&:hover": {
                    backgroundColor: "black",
                    boxShadow: 2,
                    transition: ".7s ease-in",
                  },
                  "&:disabled": {
                    color: "white",
                    backgroundColor: "#32363F !important",
                  },
                }}
                disabled={manipulationPermission}
                onClick={handleSendEmailToApplicant}
              >
                Email to Applicant
                {emailButtonLoader ? <CircularProgressLoader /> : ""}
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{
                  width: "286px",
                  height: "46px",
                  fontFamily: "Poppins-Bold",
                  backgroundColor: "#32363F",
                  borderRadius: "10px",
                  color: "white",
                  [`@media screen and (max-width: 1024px)`]: {
                    width: "100%",
                  },
                  "&:hover": {
                    backgroundColor: "black",
                    boxShadow: 2,
                    transition: ".7s ease-in",
                  },
                  "&:disabled": {
                    color: "white",
                    backgroundColor: "#32363F !important",
                  },
                }}
                disabled={manipulationPermission}
                onClick={handleSendEmailToApplicant}
              >
                Email to Applicant
                {emailButtonLoader ? <CircularProgressLoader /> : ""}
              </Button>
            )}

            {/* download pdf button  */}

            {pdfButtonEnable === true ? (
              <PDFDownloadLink
                document={
                  <OccupancyLetterPdf
                    data={occupancyLetterList}
                    muncipalityList={muncipalityList}
                  />
                }
                fileName={`Occupancy-letter-${application?.id}-${Date.now()}`}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "286px",
                    height: "46px",
                    fontFamily: "Poppins-Bold",
                    backgroundColor: "#32363F",
                    borderRadius: "10px",
                    color: "white",
                    [`@media screen and (max-width: 1024px)`]: {
                      width: "100%",
                    },
                    "&:hover": {
                      backgroundColor: "black",
                      boxShadow: 2,
                      transition: ".7s ease-in",
                    },
                  }}
                >
                  Download Occupancy letter
                </Button>
              </PDFDownloadLink>
            ) : (
              <Button
                variant="contained"
                sx={{
                  width: "286px",
                  height: "46px",
                  fontFamily: "Poppins-Bold",
                  backgroundColor: "#32363F",
                  borderRadius: "10px",
                  color: "white",
                  [`@media screen and (max-width: 1024px)`]: {
                    width: "100%",
                  },
                  "&:hover": {
                    backgroundColor: "black",
                    boxShadow: 2,
                    transition: ".7s ease-in",
                  },
                  "&:disabled": { color: "white", backgroundColor: "#32363F" },
                }}
                disabled={manipulationPermission}
                onClick={() =>
                  fireToasterContext.fireToasterHandler(
                    false,
                    "Please save information of occupancy letter first!"
                  )
                }
              >
                Download Occupancy letter
              </Button>
            )}

            <Button
              onClick={handleSave}
              sx={
                btnLoading
                  ? {
                      width: "200px",
                      height: "46px",
                      background: "#979797",
                      borderRadius: "10px",
                      color: "white",
                      [`@media screen and (max-width: 1024px)`]: {
                        width: "100%",
                      },
                      "&:hover": { backgroundColor: "#979797" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
                  : {
                      width: "200px",
                      height: "46px",
                      [`@media screen and (max-width: 1024px)`]: {
                        width: "100%",
                      },
                      backgroundColor: "background.bgLayoutB",
                      borderRadius: "10px",
                      color: "white",
                      fontFamily: "Poppins-Bold",
                      "&:hover": {
                        backgroundColor: "red",
                        boxShadow: 2,
                        transition: ".7s ease-in",
                      },
                      "&:disabled": {
                        backgroundColor: "background.bgLayoutB",
                        color: "white",
                      },
                    }
              }
              disabled={manipulationPermission}
            >
              Save
              {btnLoading ? <CircularProgressLoader /> : ""}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </>
  );
};

export default Index;
