import React, { useContext, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
  CardHeader,
  TextField,
  Stack,
  Box,
  SvgIcon,
} from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import CircularProgressLoader from "../../../utils/CircularProgressLoader";
import {
  applicationCardStyle,
  miniScrollBarStyles,
  textfield,
  textfieldDate,
  userHeading,
} from "../../../utils/muiComponentStyles";
import ToasterContext from "../../../utils/context/toasterContext";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateContractor } from "../../../redux/slices/contractorSlice";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedContractor = JSON.parse(
    localStorage.getItem("selectedContractor")
  );
  const [hvacEmailError, setHvacEmailError] = useState(false);
  const [heatObject, setHeatObject] = useState(selectedContractor);
  const fireToasterContext = useContext(ToasterContext);
  const [saveHvacContractorPressed, setSaveHvacContractorPressed] =
    useState(false);
  const [hvacContractorLoader, setHvacContractorLoader] = useState(false);

  const handleHeatObjectChange = (event) => {
    setHeatObject({
      ...heatObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleHVACContractorApiResponse = (data) => {
    console.log("data", data);
    setHvacContractorLoader(false);
    if (data.status) {
      fireToasterContext.fireToasterHandler(
        true,
        data.message ? data.message : "contractor created"
      );
      navigate("/contractors");
      // localStorage.removeItem("selectedContractor");
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        data ? data : "Something went wrong"
      );
    }
  };

  const handleHVACContractorSubmit = () => {
    setSaveHvacContractorPressed(true);
    if (heatObject.email) {
      var pattern = new RegExp(
        /^((“[\w-\s]+“)|([\w-]+(?:\.[\w-]+)*)|(“[\w-\s]+“)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(heatObject.email)) {
        setHvacEmailError(true);
        fireToasterContext.fireToasterHandler(
          false,
          "Email is not valid. Please enter valid email address"
        );
        return;
      } else {
        setHvacEmailError(false);
      }
    }
    if (
      // heatObject?.name &&
      heatObject?.companyName &&
      heatObject?.email &&
      heatObject?.phoneNumber &&
      heatObject?.licenseNumber &&
      heatObject?.licenseExpirationDate
    ) {
      const finalHvacContractorObject = {
        type: selectedContractor.type,
        id: selectedContractor.id,
        // applicationId: selectedApplicationInfo?.id,
        name: null,
        email: heatObject?.email,
        companyName: heatObject?.companyName,
        phoneNumber: heatObject?.phoneNumber,
        licenseNumber: heatObject?.licenseNumber,
        licenseExpirationDate: heatObject?.licenseExpirationDate,
        address: heatObject?.address,
        city: heatObject?.city,
        zip: heatObject?.zip,
        temporary: selectedContractor.temporary,
      };
      setHvacContractorLoader(true);
      dispatch(
        updateContractor(
          finalHvacContractorObject,
          handleHVACContractorApiResponse
        )
      );
    } else {
      fireToasterContext.fireToasterHandler(
        false,
        "Please Fill All Required Fields For Hvac Contractor"
      );
    }
  };
  return (
    <Container>
      <Card>
        <CardHeader
          disableTypography
          title={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>{`Update ${selectedContractor?.type} Contractor details`}</Box>
              <Box
                sx={{
                  display:
                    selectedContractor.temporary === false ? "none" : "flex",
                  alignItems: "center",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <SvgIcon>
                  <ErrorOutlineRoundedIcon sx={{ color: "#d52127" }} />
                </SvgIcon>
                <Typography
                  sx={{
                    fontFamily: "Poppins-Medium",
                    fontSize: "15px",
                    ml: 1,
                  }}
                >
                  {selectedContractor.temporary === true
                    ? "Add contractor to Database"
                    : null}
                </Typography>
              </Box>
            </Stack>
          }
          sx={applicationCardStyle}
        />
        <ValidatorForm
          style={{ width: "100%" }}
          onSubmit={handleHVACContractorSubmit}
          onError={(errors) => console.log(errors)}
        >
          <CardContent
            sx={{
              mt: 2,
              mx: 2,
              height: "410px",
              overflowY: "auto",
              [`::-webkit-scrollbar`]: {
                width: "0px",
                background: "transparent",
              },
              ...miniScrollBarStyles,
            }}
          >
            <Grid container spacing={2} sx={{ my: "5px" }}>
              {/* heating name */}
              {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={!heatObject?.name && saveHvacContractorPressed}
                  helperText={
                    !heatObject?.name && saveHvacContractorPressed
                      ? "Name is required"
                      : ""
                  }
                  id="heating-contractor-name"
                  label="HVAC Contractor Name"
                  value={heatObject?.name || ""}
                  name="name"
                  sx={textfield}
                  onChange={handleHeatObjectChange}
                />
              </Grid> */}

              {/* heating company name */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={!heatObject?.companyName && saveHvacContractorPressed}
                  helperText={
                    !heatObject?.companyName && saveHvacContractorPressed
                      ? "Company name is required"
                      : ""
                  }
                  id="heating-contract-company"
                  label="HVAC Contractor Company Name"
                  value={heatObject?.companyName || ""}
                  name="companyName"
                  sx={textfield}
                  onChange={handleHeatObjectChange}
                />
              </Grid>

              {/* heating company email */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="heating-company-email"
                  label="HVAC Contractor Email"
                  type="email"
                  value={heatObject?.email || ""}
                  name="email"
                  sx={textfield}
                  onChange={handleHeatObjectChange}
                  error={
                    !!hvacEmailError ||
                    (!heatObject?.email && saveHvacContractorPressed)
                  }
                  helperText={
                    hvacEmailError ||
                    (!heatObject?.email && saveHvacContractorPressed)
                      ? "Please enter a valid email address"
                      : ""
                  }
                />
              </Grid>

              {/* heat phone number */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={!heatObject?.phoneNumber && saveHvacContractorPressed}
                  helperText={
                    !heatObject?.phoneNumber && saveHvacContractorPressed
                      ? "Phone number is required"
                      : ""
                  }
                  id="heat-phone-number"
                  label="HVAC Contractor Phone No."
                  value={heatObject?.phoneNumber || ""}
                  name="phoneNumber"
                  sx={textfield}
                  onChange={handleHeatObjectChange}
                />
              </Grid>

              {/* heat License number */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={
                    !heatObject?.licenseNumber && saveHvacContractorPressed
                  }
                  helperText={
                    !heatObject?.licenseNumber && saveHvacContractorPressed
                      ? "License number is required"
                      : ""
                  }
                  id="heat-license-number"
                  label="HVAC Contractor License/Certification No."
                  value={heatObject?.licenseNumber || ""}
                  name="licenseNumber"
                  sx={textfield}
                  onChange={handleHeatObjectChange}
                />
              </Grid>

              {/* heat license expire date */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  error={
                    !heatObject?.licenseExpirationDate &&
                    saveHvacContractorPressed
                  }
                  variant="filled"
                  helperText={
                    !heatObject?.licenseExpirationDate &&
                    saveHvacContractorPressed
                      ? "License expiration date is required"
                      : ""
                  }
                  id="heat-license-expire-date"
                  // label="HVAC Contractor License Expiration Date"
                  value={heatObject?.licenseExpirationDate?.split("T")[0]}
                  name="licenseExpirationDate"
                  onChange={handleHeatObjectChange}
                  disableUnderline
                  fullWidth
                  sx={textfieldDate}
                  label="License Expiration Date"
                  inputProps={{
                    max: "9999-12-31",
                  }}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>

              {/* heat street address */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="heat-street-address"
                  label="HVAC Contractor Street Address"
                  value={heatObject?.address || ""}
                  name="address"
                  sx={textfield}
                  onChange={handleHeatObjectChange}
                />
              </Grid>

              {/* heat city field */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="heat-city"
                  label="HVAC Contractor City"
                  value={heatObject?.city || ""}
                  name="city"
                  sx={textfield}
                  onChange={handleHeatObjectChange}
                />
              </Grid>

              {/* heat zip code */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="heat-zip-code"
                  label="HVAC Contractor Zipcode"
                  value={heatObject?.zip || ""}
                  name="zip"
                  type="number"
                  sx={textfield}
                  onChange={handleHeatObjectChange}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button
              disableRipple
              type="submit"
              disabled={hvacContractorLoader}
              sx={
                hvacContractorLoader
                  ? {
                      background: "#979797",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "187px",
                      "&:hover": { backgroundColor: "#979797" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
                  : {
                      backgroundColor: "background.bgLayoutB",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "187px",
                      "&:hover": { backgroundColor: "background.bgLayoutB" },
                      "&:disabled": {
                        backgroundColor: "#979797",
                      },
                    }
              }
            >
              <Typography sx={userHeading} variant="userHeadingBoldButton">
                Update
              </Typography>
              {hvacContractorLoader ? <CircularProgressLoader /> : ""}
            </Button>
          </CardActions>
        </ValidatorForm>
      </Card>
    </Container>
  );
};

export default Index;
